import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import uuid from "react-uuid";
import * as Yup from "yup";
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";
import { useMutation } from "@apollo/client";
import { listContacts } from "../graphql/queries";
import { createContact } from "../graphql/mutations";

const ContactForm = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [addContact] = useMutation(createContact, {
    refetchQueries: [{ query: listContacts }],
    onError: (error) => {
      console.error(error.message);
      setErrorMessage("Une erreur technique s'est produite");
    },
  });

  const [isVerifiedCaptcha, setIsVerifiedCaptcha] = useState(false);

  const onResolved = (value) => {
    if (value) {
      // console.log('Captcha value:', value);
      setIsVerifiedCaptcha(true);
    }
  };


  let history = useHistory();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
      createdAt: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(100, "Must be 100 characters or less")
        .required("Required"),
      subject: Yup.string()
        .max(100, "Must be 1 characters or less")
        .required("Required"),
      message: Yup.string()
        .max(500, "Must be 500 characters or less")
        .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: (values) => {
      const payload = {
        id: uuid(),
        name: values.name,
        email: values.email,
        subject: values.subject,
        message: values.message,
        createdAt: moment().format("LLLL"),
      };
      addContact({
        variables: { input: payload },
      });
      // alert(JSON.stringify(payload, null, 2));
      history.push("/contact-page-confirmation");
    },
  });

  return (
    <div className="contact-box text-center">
      {errorMessage && <div className="text-danger">{errorMessage}</div>}

      <form
        onSubmit={formik.handleSubmit}
        className="contact-form"
        noValidate="novalidate"
      >
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Name"
                required="required"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="text-danger">{formik.errors.name}</div>
              ) : null}
            </div>
            <div className="form-group">
              <input
                id="email"
                name="email"
                type="email"
                className="form-control"
                required="required"
                placeholder="Email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-danger">{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="subject"
                placeholder="Subject"
                required="required"
                onChange={formik.handleChange}
                value={formik.values.subject}
              />
            </div>
            {formik.touched.subject && formik.errors.subject ? (
              <div className="text-danger">{formik.errors.subject}</div>
            ) : null}
          </div>
          <div className="col-12">
            <div className="form-group">
              <textarea
                className="form-control"
                name="message"
                placeholder="Message"
                required="required"
                onChange={formik.handleChange}
                value={formik.values.message}
              />
              {formik.touched.message && formik.errors.message ? (
                <div className="text-danger">{formik.errors.message}</div>
              ) : null}
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
            <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                        onChange={onResolved}
                      />
            </div>
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-lg btn-block mt-3"    disabled={!isVerifiedCaptcha}>
              <span className="text-white pr-3">
                <i className="fas fa-paper-plane" />
              </span>
              Send Message
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
