import React, { Component } from "react";

const initData = {
  heading: "At Canadian Professional Cleaning , We Love Help Our Customers​",
  content:
    " Canadian Professional Cleaning is a leading janitorial services in Alberta. Founded more than a decade ago in reaction to the growing awareness to living as eco-friendly as possible. This is embodied in our core values which translate into the ethical, sustainable and innovative ways in which we do business. This allows us to provide the best janitorial services in the region. As a team, we are committed to ensuring that the work we do, the decisions we make are reinforced by these values and we understand that it is our behaviors that best define this commitment. We recognize our wider responsibilities. We contribute to an ethical supply chain by reducing the environmental impact of our solutions and support the communities in which we work. Our experience extends to dealing with building service contractors, retail, education, government, healthcare and the hospitality industry.    ",
  btnText: "Free Quote",
  heroThumb: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning8.jpg",
};
class HeroSection extends Component {
  state = {
    data: {},
  };
  componentDidMount() {
    this.setState({
      data: initData,
    });
  }
  render() {
    return (
      <section
        id="home"
        className="section welcome-area bg-inherit h-100vh overflow-hidden"
      >
        <div className="shapes-container">
          <div className="bg-shape" />
        </div>
        <div className="container h-100">
          <div className="row align-items-center h-100">
            {/* Welcome Intro Start */}
            <div className="col-12 col-md-7">
              <div className="welcome-intro">
                <h1>{this.state.data.heading}</h1>
                <p className="my-4">{this.state.data.content}</p>
                <a href="/quote" className="btn">
                  {this.state.data.btnText}
                </a>
              </div>
            </div>
            <div className="col-12 col-md-5">
              {/* Welcome Thumb */}
              <div className="welcome-thumb">
                <img src={this.state.data.heroThumb} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HeroSection;
