import React, { Component } from "react";

const BASE_DATA = {
  widgetTitle_1: "Services",
  widgetTitle_2: "Sales",

  widgetData_1: [

    {
      id: 1,
      text: "Janitoral Services",
      link: "/janitorial",
    },
    {
      id: 2,
      text: "Construction clean up",
      link: "/construction",
    },
    {
      id: 3,
      text: "Floor Waxing",
      link: "/floor-waxing",
    },
    {
      id: 4,
      text: "Camp Cleaning",
      link: "/camp-cleaning",
    },
    {
      id: 5,
      text: "Maid Service",
      link: "/maid-service",
    },
    {
      id: 6,
      text: "Disinfecting (BYO PLANET)",
      link: "/byo-planet",
    },
  ],
  widgetData_2: [
    {
      id: 1,
      image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/avatar-1.png",
      date: "05 Feb, 2021",
      title: "Sale of cleaning products",
    },
    {
      id: 2,
      image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/avatar-2.png",
      date: "12 Feb, 2021",
      title: "Sale Of Cleaning Machine",
    },
    {
      id: 3,
      image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/avatar-3.png",
      date: "13 Jul, 2021",
      title: "Built For Passionate People",
    },
    {
      id: 4,
      image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/avatar-4.png",
      date: "03 Oct, 2021",
      title: "Get Unlimited Offers & Plans",
    },
  ],

};

class ServiceByoSidebar extends Component {
  state = {
    data: BASE_DATA,
    widgetData_1: BASE_DATA.widgetData_1,
    widgetData_2: BASE_DATA.widgetData_2,

  };

  render() {
    return (
      <div>
        <aside className="sidebar">
          {/* Single Widget */}

          {/* Single Widget */}
          <div className="single-widget">
            {/* Category Widget */}
            <div
              className="accordions widget catagory-widget"
              id="cat-accordion"
            >
              <div className="single-accordion blog-accordion">
                <h5>
                  <a
                    role="button"
                    className="collapse show text-uppercase d-block p-3"
                    data-toggle="collapse"
                    href="#accordion1"
                  >
                    {this.state.data.widgetTitle_1}
                  </a>
                </h5>
                {/* Category Widget Content */}
                <div
                  id="accordion1"
                  className="accordion-content widget-content collapse show"
                  data-parent="#cat-accordion"
                >
                  {/* Category Widget Items */}
                  <ul className="widget-items">
                    {this.state.widgetData_1.map((item, idx) => {
                      return (
                        <li key={`wdo_${idx}`}>
                          <a href={item.link} className="d-flex p-3">
                            <span>{item.text}</span>
                            <span className="ml-auto">{item.content}</span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Single Widget */}
          <div className="single-widget">
            {/* Post Widget */}
     
          </div>
        </aside>
      </div>
    );
  }
}

export default ServiceByoSidebar;
