import React, { Component } from 'react';

const DATA = {
    "heading": "Our Team Experts",
    "headingText": "More Than 200 Facilities Cleaned Weekly. Quality Work Through Customer.",
    "headingTexttwo": "",
    "teamData": [
        {
            "id": 1,
            "image": "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning8.jpg",
            "title": "Team 1",
            "teamPost": "Team 1"
        },
        {
            "id": 2,
            "image": "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp4.jpg",
            "title": "Squad Solo 1",
            "teamPost": "Squad Solo 1"
        },
        {
            "id": 3,
            "image": "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp5.jpg",
            "title": "Squad Solo 2",
            "teamPost": "TeSquad Solo 2"
        },
        {
            "id": 4,
            "image": "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing02.jpg",
            "title": "Squad Solo 3",
            "teamPost": "TSquad Solo 3"
        },
    ],
    "teamIcons": [
        {
            "id": 1,
            "iconClass": "fab fa-facebook-f"
        },
        {
            "id": 2,
            "iconClass": "fab fa-twitter"
        },
        {
            "id": 3,
            "iconClass": "fab fa-google-plus-g"
        },
        {
            "id": 4,
            "iconClass": "fab fa-linkedin-in"
        }
    ]
};

class Team extends Component {
    state = {
        data: DATA,
        teamData: DATA.teamData,
        teamIcons: DATA.teamIcons
    }
    // componentDidMount(){
    //     axios.get(`${BASE_URL}`)
    //         .then(res => {
    //             this.setState({
    //                 data: res.data,
    //                 teamData: res.data.teamData,
    //                 teamIcons: res.data.teamIcons
    //             })
    //             // console.log(this.state.data)
    //         })
    //     .catch(err => console.log(err))
    // }
    render() {
        return (
            <section className="section team-area team-style-two overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        <h2 className="text-capitalize">{this.state.data.heading}</h2>
                        <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                        <p className="d-block d-sm-none mt-4">{this.state.data.headingTexttwo}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        {this.state.teamData.map((item, idx) => {
                            return(
                                <div key={`t_${idx}`} className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    {/* Single Team */}
                                    <div className="single-team text-center radius-100 overflow-hidden m-2 m-lg-0">
                                    {/* Team Thumb */}
                                    <div className="team-thumb radius-100 d-inline-block position-relative overflow-hidden">
                                        <img src={item.image} alt="" />
                                        {/* Team Overlay */}
                                        <div className="team-overlay radius-100">
                                        <h4 className="team-name text-white">{item.title}</h4>
                                        <h5 className="team-post text-white mt-2 mb-3">{item.teamPost}</h5>
                                        {/* Team Icons */}
                                        <div className="team-icons">
                                            {this.state.teamIcons.map((item, idx) => {
                                                return(
                                                    <a key={`ti_${idx}`} className="p-2" href="/#"><i className={item.iconClass} /></a>
                                                );
                                            })}
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default Team;