import React, { Component } from 'react';
import Header from '../HeaderSection/Header';
import ServiceBreadcrumb from './Breadcrumb';
import SidebarMain from './SidebarMain';
import ServiceJanitorialDetails from './ServiceJanitorialDetails';
import FooterSection from '../FooterSection/FooterTwo';

class ServiceJanitorial extends Component {
    render() {
        return (
            <div className="blog blog-right">
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header imageData={"https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/logo-canadian-cleaning.png"} />
                    <ServiceBreadcrumb title="JANITORIAL SERVICES" />
                    <section id="blog" className="section blog-area ptb_100">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-9">
                                    <ServiceJanitorialDetails />
                                </div>
                                <div className="col-12 col-lg-3">
                                    <SidebarMain />
                                </div>
                            </div>
                        </div>
                    </section>
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default ServiceJanitorial;