import React, { useState } from "react";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';


function CalendarSection() {
    const [value, onChange] = useState(new Date());

    return (
        <section
            id="pricing"
            className="section price-plan-area bg-gray overflow-hidden ptb_100"
        >
            <div className="row justify-content-center pt-5">

                <Calendar onChange={onChange} value={value} />
            </div>
            <div className="row justify-content-center pt-5">
                <p className="text-body pt-4 fw-5">
                Not sure what to choose? <a href="/contact-page"> Contact Us</a>
                </p>
            </div>

        </section>
    );
}

export default CalendarSection;
