import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import uuid from "react-uuid";
import { useMutation } from "@apollo/client";
import { listContacts } from "../graphql/queries";
import { createContact } from "../graphql/mutations";

import * as Yup from "yup";
import moment from "moment";
import Header from "../HeaderSection/Header";

const Quote = () => {

  const [errorMessage, setErrorMessage] = useState(null);
  const [addContact] = useMutation(createContact, {
    refetchQueries: [{ query: listContacts }],
    onError: (error) => {
      console.error(error.message);
      setErrorMessage("Une erreur technique s'est produite");
    },
  });

  const [isVerifiedCaptcha, setIsVerifiedCaptcha] = useState(false);

  const onResolved = (value) => {
    if (value) {
      // console.log('Captcha value:', value);
      setIsVerifiedCaptcha(true);
    }
  };


  
  const initData = {
    heading: "Quote!",
    content:
      "Canadian Professional Cleaning is a leading janitorial services in Alberta. Founded more than a decade ago in reaction to the growing awareness to living as eco-friendly as possible … More",
    formHeading: "Quote",
    formContent:
      "Fill all fields so we can get some info about you. We'll never send you spam",
    formText: "Already have an account?",
    btnText: "Quote",
    btnText_2: "Sign In",
  };

  let history = useHistory();

  const formik = useFormik({
    initialValues: {
      name: "",
      phoneNumber: "",
      email: "",
      createdAt: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(100, "Must be 100 characters or less")
        .required("Required"),
      phoneNumber: Yup.string()
        .max(100, "Must be 100 characters or less")
        .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: (values) => {
      const payload = {
        id: uuid(),
        name: values.name,
        phoneNumber: values.phoneNumber,
        email: values.email,
        message: values.message,
        createdAt: moment().format("LLLL"),
      };

         addContact({
        variables: { input: payload },
      });
      // alert(JSON.stringify(payload, null, 2));
      history.push("/contact-page-confirmation");
    },
  });



  return (
    <div className="accounts inner-pages signup">

{errorMessage && <div className="text-danger">{errorMessage}</div>}

      <div className="main">
        <Header
          imageData={
            "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/logo-canadian-cleaning.png"
          }
        />
        <section
          id="home"
          className="section welcome-area h-100vh bg-overlay d-flex align-items-center"
        >
          <div className="container">
            <div className="row align-items-center justify-content-center">
              {/* Welcome Intro Start */}
              <div className="col-12 col-lg-3">
                <div className="welcome-intro">
                  <h1 className="text-white">{initData.heading}</h1>
                  <p className="text-white my-4">{initData.content}</p>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-9">
                {/* Contact Box */}
                <div className="contact-box bg-white text-center rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg">
                  {/* Contact Form */}
                  <form
                    onSubmit={formik.handleSubmit}
                    className="contact-form"
                    noValidate="novalidate"
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Name"
                            required="required"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className="text-danger">
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            className="form-control"
                            required="required"
                            placeholder="Email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="phoneNumber"
                            placeholder="Phone Number"
                            required="required"
                            onChange={formik.handleChange}
                            value={formik.values.phoneNumber}
                          />
                        </div>
                        {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber ? (
                          <div className="text-danger">
                            {formik.errors.phoneNumber}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            name="message"
                            placeholder="Message"
                            required="required"
                            onChange={formik.handleChange}
                            value={formik.values.message}
                          />
                          {formik.touched.message && formik.errors.message ? (
                            <div className="text-danger">
                              {formik.errors.message}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                        <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                        onChange={onResolved}
                      />
                          
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          disabled={!isVerifiedCaptcha}
                          className="btn btn-lg btn-block mt-3"
                        >
                          <span className="text-white pr-3">
                            <i className="fas fa-paper-plane" />
                          </span>
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Shape Bottom */}
          <div className="shape-bottom">
            <svg
              viewBox="0 0 1920 310"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              className="svg replaced-svg"
            >
              <title>Canadian Professional Cleaning Shape</title>
              <desc>Created with Sketch</desc>
              <defs />
              <g
                id="sApp-Landing-Page"
                stroke="none"
                strokeWidth={1}
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="sApp-v1.0"
                  transform="translate(0.000000, -554.000000)"
                  fill="#FFFFFF"
                >
                  <path
                    d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
                    id="sApp-v1.0"
                  />
                </g>
              </g>
            </svg>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Quote;
