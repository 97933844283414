import { gql } from "@apollo/client";

export const createContact = gql`
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      subject
      name
      email
      message
      createdAt
      phoneNumber
      updatedAt
    }
  }
`;
export const updateContact = gql`
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      subject
      name
      email
      message
      createdAt
      phoneNumber
      updatedAt
    }
  }
`;
export const deleteContact = gql`
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      subject
      name
      email
      message
      createdAt
      phoneNumber
      updatedAt
    }
  }
`;
export const createVisitor = gql`
  mutation CreateVisitor(
    $input: CreateVisitorInput!
    $condition: ModelVisitorConditionInput
  ) {
    createVisitor(input: $input, condition: $condition) {
      id
      name
      page
      sitename
      city
      countryName
      countryCode
      postal
      latitude
      longitude
      ip
      stateName
      createdAt
      updatedAt
    }
  }
`;
export const updateVisitor = gql`
  mutation UpdateVisitor(
    $input: UpdateVisitorInput!
    $condition: ModelVisitorConditionInput
  ) {
    updateVisitor(input: $input, condition: $condition) {
      id
      name
      page
      sitename
      city
      countryName
      countryCode
      postal
      latitude
      longitude
      ip
      stateName
      createdAt
      updatedAt
    }
  }
`;
export const deleteVisitor = gql`
  mutation DeleteVisitor(
    $input: DeleteVisitorInput!
    $condition: ModelVisitorConditionInput
  ) {
    deleteVisitor(input: $input, condition: $condition) {
      id
      name
      page
      sitename
      city
      countryName
      countryCode
      postal
      latitude
      longitude
      ip
      stateName
      createdAt
      updatedAt
    }
  }
`;
