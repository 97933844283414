import React, { Component } from 'react';
import ContactForm from './ContactForm';
import Header from '../HeaderSection/Header';
import Breadcrumb from '../Blogs/Breadcrumb';
import FooterSection from '../FooterSection/Footer';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';


const mapStyles = {
    width: '90%',
    height: '90%',
};


const DATA = {
    "heading": "Stay Tuned",
    "headingText": "Canadian Professional Cleaning",
    "headingTexttwo": "Get In Contact With Us",
    "content": "Get In Contact With Us",
    "iconList": [
        {
            "id": 1,
            "iconClass": "fas fa-home",
            "text": "Box 5355,  Drayton Valley, AB, T7A 1R5, Canada"
        },
        {
            "id": 2,
            "iconClass": "fas fa-phone-alt",
            "text": "+1 780-241-4444"
        },
        {
            "id": 3,
            "iconClass": "fas fa-envelope",
            "text": ""
        }
    ]
};


class ContactPage extends Component {
    state = {
        data: DATA,
        iconList: DATA.iconList,
        showingInfoWindow: false,  // Hides or shows the InfoWindow
        activeMarker: {},          // Shows the active marker upon click
        selectedPlace: {}          // Shows the InfoWindow to the selected place upon a marker
    }
    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };
    render() {
        return (
            <div className="contact-page">
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header imageData={"https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/logo-canadian-cleaning.png"} />
                    <Breadcrumb title="Contact Us" />
                    <section id="contact" className="contact-area ptb_100">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-10 col-lg-6">
                                    {/* Section Heading */}
                                    <div className="section-heading">
                                        <h2 className="text-capitalize">{this.state.data.heading}</h2>
                                        <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                                        <p className="d-block d-sm-none mt-4">{this.state.data.headingTexttwo}</p>

                                    </div>

                                </div>

                            </div>

                            <div className="row justify-content-between">
                                <div className="col-12 col-md-5">
                                    {/* Contact Us */}
                                    <div className="contact-us">
                                        <p className="mb-3">{this.state.data.content}</p>
                                        <ul>
                                            {this.state.iconList.map((item, idx) => {
                                                return (
                                                    <li key={`ci_${idx}`} className="py-2">
                                                        <a className="media" href="/#">
                                                            <div className="social-icon mr-3">
                                                                <i className={item.iconClass} />
                                                            </div>
                                                            <span className="media-body align-self-center">{item.text}</span>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>

                                    </div>
                                </div>
                                <div className="col-12 col-md-6 pt-4 pt-md-0">
                                    <ContactForm />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*====== Map Area Start ======*/}
                    <section className="section map-area">
                        <Map
                            google={this.props.google}
                            zoom={14}
                            style={mapStyles}
                            initialCenter={
                                {
                                    lat: 53.2231685,
                                    lng: -114.9850469
                                }
                            }
                        >
                            <Marker
                                onClick={this.onMarkerClick}
                                name={'Canadian Professional Cleaning'}
                            />
                            <InfoWindow
                                marker={this.state.activeMarker}
                                visible={this.state.showingInfoWindow}
                                onClose={this.onClose}
                            >
                                <div>
                                    <h4>{this.state.selectedPlace.name}</h4>
                                </div>
                            </InfoWindow>
                        </Map>
                    </section>
                    {/*====== Map Area End ======*/}
                    <FooterSection />
                </div>
            </div>
        );
    }
}


export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
})(ContactPage)
