import React, { useState } from "react";
import { Button, Modal, ListGroup } from "react-bootstrap";
import {  Header } from "semantic-ui-react";
import ImageGallery from "react-image-gallery";

const data = [
  {
    image: "",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services2.jpeg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services3.jpeg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services4.jpeg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services5.jpeg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services6.jpeg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services7.jpeg",
  },
];

const images = [
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services2.jpeg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services2.jpeg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services3.jpeg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services3.jpeg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services4.jpeg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services4.jpeg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services5.jpeg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services5.jpeg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services2.jpeg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services2.jpeg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services6.jpeg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services6.jpeg",
  },
];

const CONTENT = {
  title: "MAID SERVICES",
  image:
    "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/maid-services1.jpg",
  author: "Anna Swford",
  date: "05 Feb, 2021",
  comments: "2 Comments",
  text_1:
    "At Canadian Professional Cleaning we use professionally trained, courteous two person teams to clean your home",
  text_2: "text_2",
  text_3:
    "We provide prompt telephone service and free on-site estimates for your convenience.",
  text_5:
    "Our cleaning teams are fully equipped with equipment and products to leave your home sparkling clean. Our cleaning products are environmentally friendly, which means a healthier environment inside and out. Our staff are uniformed, arrive in a company vehicle and are bonded and insured for your peace of mind.",
  text_6:
    "We are so confident that you will be completely satisfied with your cleaning that we guarantee all of our work",
  text_7:
    "At Canadian Professional Cleaning we offer Comprehensive Maid Services that include:",
  quoteText_1:
    "Whether you are moving, looking for a regularly scheduled clean, have had renovations or need a one-time specialty project, we can meet your needs",
  text_8:
    "Have a big project? No Problem! Our staff is trained to handle all types of cleans.",
  text_9:
    "Gift certificates are available for all occasions. Not sure what to buy? Call our sales team and they will be happy to recommend an appropriate service for you. ",
  admin: "",
  adminText: "",
  commentsTitle: "commentsTitle",
  commentsPost: "commentsPost",
  postText: "postText",
  iconList: [
    {
      id: 1,
      link: "bg-white facebook",
      iconClass: "fab fa-facebook-f",
    },
    {
      id: 2,
      link: "bg-white twitter",
      iconClass: "fab fa-twitter",
    },
    {
      id: 3,
      link: "bg-white google-plus",
      iconClass: "fab fa-google-plus-g",
    },
  ],
  commentsData: [
    {
      id: 1,
      commentsImage:
        "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/avatar-2.png",
      commentsAuthor: "Junaid Hasan",
      replyText: "Reply",
      commentsText_1: "",
      commentsText_2: "",
    },
    {
      id: 2,
      commentsImage:
        "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/avatar-3.png",
      commentsAuthor: "Jassica William",
      replyText: "Reply",
      commentsText_1: "",
      commentsText_2: ".",
    },
  ],
};

function ServiceByoDetails() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // state = {
  //   data: CONTENT,
  //   iconList: CONTENT.iconList,
  //   commentsData: CONTENT.commentsData,
  // };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> Maid services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageGallery items={images} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Single Blog Details */}
      <article className="single-blog-details">
        {/* Blog Thumb */}
        <div className="blog-thumb">
          <a href="# " onClick={handleShow}>
            <img src={CONTENT.image} alt="" />
          </a>
        </div>
        {/* Blog Content */}
        <div className="blog-content sApp-blog">
          {/* Meta Info */}
          <div className="meta-info d-flex flex-wrap align-items-center py-2">
            {/* <ul>
                                <li className="d-inline-block p-2">By <a className="text-primary" href="/#">{data.author}</a></li>
                                <li className="d-inline-block p-2"><a href="/#">{data.date}</a></li>
                                <li className="d-inline-block p-2"><a href="/#">{data.comments}</a></li>
                            </ul> */}
            {/* Blog Share */}
            <div className="blog-share ml-auto d-none d-sm-block">
              {/* Social Icons */}
              <div className="social-icons d-flex justify-content-center">
                {CONTENT.iconList.map((item, idx) => {
                  return (
                    <a key={`bdi_${idx}`} className={item.link} href="https://www.facebook.com/canadianprocleaning/photos">
                      <i className={item.iconClass} />
                      <i className={item.iconClass} />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
          {/* Blog Details */}
          <div className="blog-details">
            <h3 className="blog-title py-3">
              <a href="/#">{CONTENT.title}</a>
            </h3>
            <p className="d-none d-sm-block">{CONTENT.text_1}</p>
            <p className="d-block d-sm-none">{CONTENT.text_2}</p>
            <blockquote className="blockquote px-4 py-3 my-3 ml-4">
              <p className="d-none d-sm-block">{CONTENT.quoteText_1}</p>
              <p className="d-block d-sm-none">{CONTENT.quoteText_2}</p>
            </blockquote>
            <p className="d-none d-sm-block">{CONTENT.text_3}</p>
            <p className="d-block d-sm-none">{CONTENT.text_4}</p>
          </div>
        </div>
        <Header size="medium">About Our Maid Services.</Header>
        <div className="blog-details">
          <p className="d-none d-sm-block">{CONTENT.text_5}</p>
        </div>

        <Header size="medium">Our Service Guarantee.</Header>
        <div className="blog-details">
          <p className="d-none d-sm-block">{CONTENT.text_6}</p>
          <p className="d-none d-sm-block">{CONTENT.text_7}</p>
        </div>
        <div className="blog-details">
          <p className="d-none d-sm-block">{CONTENT.text_5}</p>

          <ListGroup as="ol" numbered>
            <ListGroup.Item as="li">
              Complete Dusting & Vacuuming
            </ListGroup.Item>
            <ListGroup.Item as="li">
              {" "}
              Cleaning of Glass, Mirrors, Counters, Fixtures, Appliances,
              Furniture
            </ListGroup.Item>
            <ListGroup.Item as="li">
              Deep Cleaning of Bathrooms & Kitchens
            </ListGroup.Item>
            <ListGroup.Item as="li">
              {" "}
              All Equipment, Supplies & Transportation
            </ListGroup.Item>
            <ListGroup.Item as="li">Move In / Move Out Cleaning</ListGroup.Item>
            <ListGroup.Item as="li">
              Bonded, Insured, Uniformed & Supervised Staff
            </ListGroup.Item>
            <ListGroup.Item as="li">
              {" "}
              Environmentally Friendly Cleaning Products & Practices
            </ListGroup.Item>
          </ListGroup>

          <p className="d-none d-sm-block">{CONTENT.text_8}</p>

          <p className="d-none d-sm-block">{CONTENT.text_9}</p>
        </div>
        <div className="row">
          <div className="col-12">
            {/* App Screenshot Slider Area */}
            <div className="app-screenshots">
              {/* Single Screenshot Item */}
              {data.map((item, idx) => {
                return (
                  <div key={`so_${idx}`} className="single-screenshot">
                    <img src={item.image} alt="" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}

export default ServiceByoDetails;
