const prod = {
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
  s3: {
    REGION: process.env.REACT_APP_S3_REGION,
    BUCKET: process.env.REACT_APP_S3_BUCKET,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_API_REGION,
    URL: process.env.REACT_APP_API_GATEWAY_ENDPOINT,
  },
  cognito: {
    REGION: process.env.REACT_APP_COGNITO_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  },
  sqs: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_SQS_ENDPOINT,
  },
  AppSync: {
    REGION: process.env.REACT_APP_APPSYNC_GRAPHQL_REGION,
    GRAPHQL_ENDPOINT: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT,
    TYPE_AUTH: process.env.REACT_APP_APPSYNC_GRAPHQL_TYPE_AUTH,
    API_KEY: process.env.REACT_APP_APPSYNC_GRAPHQL_API_KEY,
  },
};

const dev = {
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
  s3: {
    REGION: process.env.REACT_APP_S3_REGION,
    BUCKET: process.env.REACT_APP_S3_BUCKET,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_API_REGION,
    URL: process.env.REACT_APP_API_GATEWAY_ENDPOINT,
  },
  cognito: {
    REGION: process.env.REACT_APP_COGNITO_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  },
  sqs: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_SQS_ENDPOINT,
  },
  AppSync: {
    REGION: process.env.REACT_APP_APPSYNC_GRAPHQL_REGION,
    GRAPHQL_ENDPOINT: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT,
    TYPE_AUTH: process.env.REACT_APP_APPSYNC_GRAPHQL_TYPE_AUTH,
    API_KEY: process.env.REACT_APP_APPSYNC_GRAPHQL_API_KEY,
  },
};
const amplifyConfig = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  // Default to dev if not set
  ...(process.env.REACT_APP_STAGE === "prod" ? prod : dev),
};

export default amplifyConfig;
