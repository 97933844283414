import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
  render() {
    return (
      <header className="navbar navbar-sticky navbar-expand-lg navbar-dark">
        <div className="container position-relative">
          <a className="navbar-brand" href="/">
            <img
              className="navbar-brand-regular"
              src={this.props.imageData}
              alt="brand-logo"
            />
            <img
              className="navbar-brand-sticky"
              src="https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/logo-canadian-cleaning.png"
              alt="sticky brand-logo"
            />
          </a>
          <button
            className="navbar-toggler d-lg-none"
            type="button"
            data-toggle="navbarToggler"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="navbar-inner">
            {/*  Mobile Menu Toggler */}
            <button
              className="navbar-toggler d-lg-none"
              type="button"
              data-toggle="navbarToggler"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <nav>
              <ul className="navbar-nav" id="navbar-nav">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Home
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    {/* <li>
                                            <a className="dropdown-item" href="/">Homepage-1</a>
                                        </li> */}
                    <li>
                      <a className="dropdown-item" href="/about">
                        About Us
                      </a>
                    </li>
                    {/* <li>
                                            <a className="dropdown-item" href="/theme-three">Homepage-3</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/theme-four">Homepage-4</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/theme-five">Homepage-5</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/theme-six">Homepage-6</a>
                                        </li> */}
                  </ul>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link scroll" href="#features">
                    Top Features
                  </a>
                </li> */}
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Services
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    {/* <li className="dropdown-submenu">
                      <a
                        className="dropdown-item dropdown-toggle"
                        href="/#"
                        data-toggle="dropdown"
                      >
                        Inner Pages
                        <span className="badge badge-pill badge-warning ml-2">
                          New
                        </span>
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="/pricing">
                            Pricing
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/download-page">
                            Download
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/subscribe-page">
                            Newsletter
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/thank-you">
                            Thank you
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/coming-soon">
                            Coming Soon
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/error-page">
                            404
                          </a>
                        </li>
                      </ul>
                    </li> */}
                    {/* <li className="dropdown-submenu">
                      <a
                        className="dropdown-item dropdown-toggle"
                        href="/#"
                        data-toggle="dropdown"
                      >
                       Cleaning
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="/blog-two-column">
                            Blog- 2 Column
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/blog-three-column"
                          >
                            Blog- 3 Column
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/blog-left-sidebar"
                          >
                            Blog- Left Sidebar
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/blog-right-sidebar"
                          >
                            Blog- Right Sidebar
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown-submenu">
                      <a
                        className="dropdown-item dropdown-toggle"
                        href="/#"
                        data-toggle="dropdown"
                      >
                        Blog Details
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            href="/blog-details-left-sidebar"
                          >
                            Blog Details- Left Sidebar
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/blog-details-right-sidebar"
                          >
                            Blog Details- Right Sidebar
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown-submenu">
                      <a
                        className="dropdown-item dropdown-toggle"
                        href="/#"
                        data-toggle="dropdown"
                      >
                        Accounts
                        <span className="badge badge-pill badge-warning ml-2">
                          New
                        </span>
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="/login">
                            Login
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/signup">
                            Signup
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/forgot">
                            Reset Password
                          </a>
                        </li>
                      </ul>
                    </li> */}
                    <li>
                      <a className="dropdown-item" href="/janitorial">
                        JANITORIAL SERVICES
                      </a>
                    </li>

                    <li>
                      <a className="dropdown-item" href="/construction">
                        CONSTRUCTION CLEAN UP
                      </a>
                    </li>

                    <li>
                      <a className="dropdown-item" href="/floor-waxing">
                        FLOOR WAXING
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/camp-cleaning">
                        CAMP CLEANING
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/maid-service">
                        MAID SERVICES
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/byo-planet">
                      Disinfecting (BYO PLANET)
                      </a>
                    </li>
                    {/* <li>
                      <a className="dropdown-item disabled" href="/#">
                        More Coming Soon
                      </a>
                    </li> */}
                  </ul>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link scroll" href="#screenshots">
                    At Work
                  </a>
                </li> */}
                {/* <li className="nav-item">
                  <Link className="nav-link" to={"./pricing"}>
                  Pricing
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to={"./quote"}>
                    Quote
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"./contact-page"}>
                    Contact
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to={"./login"}>
                    Login
                  </Link>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
