import React, { Component } from 'react';

const CONTENT = {
    "image": "/img/logo.png",
    "text": "Canadian Professional Cleaning is a leading janitorial services in Alberta. ",
    "linkText_1": "Useful Links",
    "linkText_2": "Product Help",
    "linkText_3": "",
    "iconList": [
        {
            "id": 1,
            "link": "facebook",
            "iconClass": "fab fa-facebook-f"
        },
        {
            "id": 2,
            "link": "twitter",
            "iconClass": "fab fa-twitter"
        },
        {
            "id": 3,
            "link": "google-plus",
            "iconClass": "fab fa-google-plus-g"
        },
        {
            "id": 4,
            "link": "vine",
            "iconClass": "fab fa-vine"
        }
    ],
    "footerList_1": [
        {
            "id": 1,
            "text": "Home"
        },
        {
            "id": 2,
            "text": "About Us"
        },
        {
            "id": 3,
            "text": "Services"
        },
        {
            "id": 4,
            "text": "Blog"
        },
        {
            "id": 5,
            "text": "Contact"
        }
    ],
    "footerList_2": [
        {
            "id": 1,
            "text": "FAQ"
        },
        {
            "id": 2,
            "text": "Privacy Policy"
        },
        {
            "id": 3,
            "text": "Support"
        },
        {
            "id": 4,
            "text": "Terms & Conditions"
        },
        {
            "id": 5,
            "text": "Contact"
        }
    ],
    "footerList_3": [
        {
            "id": 1,
            "image": "/img/google-play-black.png"
        },
        {
            "id": 2,
            "image": "/img/app-store-black.png"
        }
    ],
    "footerList_4": [
        {
            "id": 1,
            "text": "Affiliate program"
        },
        {
            "id": 2,
            "text": "Terms & Conditions"
        },
        {
            "id": 3,
            "text": "Privacy Policy"
        },
        {
            "id": 4,
            "text": "Refund Policy"
        }
    ]
};

class FooterSection extends Component {
    state = {
        data: CONTENT,
        iconList: CONTENT.iconList,
        footerList_1: CONTENT.footerList_1,
        footerList_2: CONTENT.footerList_2,
        footerList_3: CONTENT.footerList_3
    }
    // componentDidMount(){
    //     axios.get(`${BASE_URL}`)
    //         .then(res => {
    //             this.setState({
    //                 data: res.data,
    //                 iconList: res.data.iconList,
    //                 footerList_1: res.data.footerList_1,
    //                 footerList_2: res.data.footerList_2,
    //                 footerList_3: res.data.footerList_3
    //             })
    //             // console.log(this.state.data)
    //         })
    //     .catch(err => console.log(err))
    // }
    render() {
        return (
            <div>
                <div className="height-emulator d-none d-lg-block" />
                <footer className="footer-area footer-fixed">
                    {/* Footer Top */}
                    <div className="footer-top ptb_100">
                        <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Logo */}
                                <a className="navbar-brand" href="/#">
                                <img className="logo" src={this.state.data.image} alt="" />
                                </a>
                                <p className="mt-2 mb-3">{this.state.data.text}</p>
                                {/* Social Icons */}
                                <div className="social-icons d-flex">
                                    {this.state.iconList.map((item, idx) => {
                                        return(
                                            <a key={`fi_${idx}`} className={item.link} href="/#">
                                                <i className={item.iconClass} />
                                                <i className={item.iconClass} />
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                            </div>
  
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h3 className="footer-title mb-2">{this.state.data.linkText_3}</h3>
                                {/* Store Buttons */}
                                <div className="button-group store-buttons store-black d-flex flex-wrap">
                                    {this.state.footerList_3.map((item, idx) => {
                                        return(
                                            <a key={`flth_${idx}`} href="/#">
                                                <img src={item.image} alt="" />
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* Footer Bottom */}
                    <div className="footer-bottom">
                        <div className="container">
                        <div className="row">
                            <div className="col-12">
                            {/* Copyright Area */}
                            <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                {/* Copyright Left */}
                                <div className="copyright-left">© Copyrights 2021 Canadian Professional Cleaning All rights reserved.</div>
                                {/* Copyright Right */}
                                {/* <div className="copyright-right">Made with <i className="fas fa-heart" /> By <a href="/#">Theme Land</a></div> */}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default FooterSection;