import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import { Button, Modal, ListGroup } from "react-bootstrap";
import {  Header } from "semantic-ui-react";

const images = [
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning1.jpg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning1.jpg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning2.jpg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning2.jpg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning3.jpg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning3.jpg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning4.jpg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning4.jpg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning5.jpg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning5.jpg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning7.jpg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning7.jpg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning8.jpg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning8.jpg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning9.jpg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning9.jpg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning10.jpg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning10.jpg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning11.jpg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning11.jpg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning12.jpg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning12.jpg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning13.jpg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning13.jpg",
  },
];

const data = [
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning1.jpg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning2.jpg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning3.jpg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning4.jpg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning5.jpg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning6.jpg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning7.jpg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning8.jpg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning9.jpg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning10.jpg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning11.jpg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning12.jpg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning13.jpg",
  },
];

const CONTENT = {
  title: "CAMP CLEANING",
  image:
    "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/camp-cleaning1.jpg",
  author: "Anna Swford",
  date: "05 Feb, 2021",
  comments: "2 Comments",
  text_1:
    "The health and safety of all staff and guests is a paramount concern of Canadian Professional Cleaning Inc. As part of our workforce camp operations, Canadian Professional Cleaning Inc. provides housekeeping and janitorial services to maintain a clean, safe camp.",
  text_2: "text_2",
  text_3: "text_3",
  text_4:
    "Canadian Professional Cleaning Inc. follows safe work procedures and trains staff to meet the highest standards.",
  quoteText_1:
    "This includes the provision and maintenance of all equipment, tools and consumables for performing housekeeping and janitorial services.",
  quoteText_2: "quoteText_2",
  adminImage: "",
  admin: "",
  adminText: "",
  commentsTitle: "commentsTitle",
  commentsPost: "commentsPost",
  postText: "postText",
  iconList: [
    {
      id: 1,
      link: "bg-white facebook",
      iconClass: "fab fa-facebook-f",
    },
    {
      id: 2,
      link: "bg-white twitter",
      iconClass: "fab fa-twitter",
    },
    {
      id: 3,
      link: "bg-white google-plus",
      iconClass: "fab fa-google-plus-g",
    },
  ],
  commentsData: [
    {
      id: 1,
      commentsImage:
        "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/avatar-2.png",
      commentsAuthor: "Junaid Hasan",
      replyText: "Reply",
      commentsText_1: "",
      commentsText_2: "",
    },
    {
      id: 2,
      commentsImage:
        "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/avatar-3.png",
      commentsAuthor: "Jassica William",
      replyText: "Reply",
      commentsText_1: "",
      commentsText_2: "",
    },
  ],
};

function ServiceByoDetails() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // state = {
  //   data: CONTENT,
  //   iconList: CONTENT.iconList,
  //   commentsData: CONTENT.commentsData,
  // };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> Camp cleaning services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageGallery items={images} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Single Blog Details */}
      <article className="single-blog-details">
        {/* Blog Thumb */}
        <div className="blog-thumb">
          <a href="# " onClick={handleShow}>
            <img src={CONTENT.image} alt="" />
          </a>
        </div>
        {/* Blog Content */}
        <div className="blog-content sApp-blog">
          {/* Meta Info */}
          <div className="meta-info d-flex flex-wrap align-items-center py-2">
            {/* <ul>
                                <li className="d-inline-block p-2">By <a className="text-primary" href="/#">{data.author}</a></li>
                                <li className="d-inline-block p-2"><a href="/#">{data.date}</a></li>
                                <li className="d-inline-block p-2"><a href="/#">{data.comments}</a></li>
                            </ul> */}
            {/* Blog Share */}
            <div className="blog-share ml-auto d-none d-sm-block">
              {/* Social Icons */}
              <div className="social-icons d-flex justify-content-center">
                {CONTENT.iconList.map((item, idx) => {
                  return (
                    <a key={`bdi_${idx}`} className={item.link} href="https://www.facebook.com/canadianprocleaning/photos">
                      <i className={item.iconClass} />
                      <i className={item.iconClass} />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
          {/* Blog Details */}
          <div className="blog-details">
            <h3 className="blog-title py-3">
              <a href="/#">{CONTENT.title}</a>
            </h3>
            <p className="d-none d-sm-block">{CONTENT.text_1}</p>
            <p className="d-block d-sm-none">{CONTENT.text_2}</p>
            <blockquote className="blockquote px-4 py-3 my-3 ml-4">
              <p className="d-none d-sm-block">{CONTENT.quoteText_1}</p>
              <p className="d-block d-sm-none">{CONTENT.quoteText_2}</p>
            </blockquote>
          </div>
        </div>
        <Header size="medium">
          Housekeeping services are performed on a cyclical cleaning schedule:
        </Header>

        <div className="blog-details">
          <p className="d-none d-sm-block">{CONTENT.text_5}</p>
          <ListGroup as="ol" numbered>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              {" "}
              Daily cleaning of occupied rooms – making beds, garbage removal,
              dusting, floors, bathrooms
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              Replacing bedding and linen items
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              {" "}
              Cleaning surfaces in public areas – reception, dining facilities,
              washrooms, fitness centre
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              Operating the commercial laundry
            </ListGroup.Item>
          </ListGroup>

          <p className="d-none d-sm-block">{CONTENT.text_4}</p>
        </div>

        <div className="row">
          <div className="col-12">
            {/* App Screenshot Slider Area */}
            <div className="app-screenshots">
              {/* Single Screenshot Item */}
              {data.map((item, idx) => {
                return (
                  <div key={`so_${idx}`} className="single-screenshot">
                    <img src={item.image} alt="" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}

export default ServiceByoDetails;
