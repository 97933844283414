import React, { Component } from 'react';
import Header from '../HeaderSection/Header';
import Breadcrumb from '../Blogs/Breadcrumb';
import FooterSection from '../FooterSection/Footer';
const DATA = {
    "heading": "Your message has been sent successfully",
    "headingText": "Canadian Professional Cleaning",
    "headingTexttwo": "Get In Contact With Us",
    "content": "Your message has been sent successfully",
    "iconList": [
        {
            "id": 1,
            "iconClass": "fas fa-home",
            "text": "4912 - 47 Street Drayton Valley, AB Canada"
        },
        {
            "id": 2,
            "iconClass": "fas fa-phone-alt",
            "text": "+1 780-241-4444"
        },
        {
            "id": 3,
            "iconClass": "fas fa-envelope",
            "text": "info@canadianprofessionalcleaning.com"
        }
    ]
};


class ContactPageConfirmation extends Component {
    state = {
        data: DATA,
        iconList: DATA.iconList
    }

    render() {
        return (
            <div className="contact-page">
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header imageData={"https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/logo-canadian-cleaning.png"} />
                    <Breadcrumb title="Contact Us" />
                    <section id="contact" className="contact-area ptb_100">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-10 col-lg-12">
                                    {/* Section Heading */}
                                    <div className="section-heading">
                                        <h2 className="text-capitalize">{this.state.data.heading}</h2>
                                        <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                                      
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default ContactPageConfirmation;