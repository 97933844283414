import React, { Component } from "react";
import { Embed } from "semantic-ui-react";

const CONTENT = {
  title: "Disinfecting (BYO PLANET)",
  image:
    "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/bio-planet01.jpg",
  author: "Anna Swford",
  date: "05 Feb, 2021",
  comments: "2 Comments",
  text_1:
    "Canadian Professional Cleaning uses sophisticated electrostatic technologies and proven EPA product testing provide the most proactive surface treatments available today. Rigorously field tested and proven to kill 99.999% of today’s worst bacteria, viruses, mold, mildew and odor – eliminate odors at the source, control pests and enhance existing mold remediation techniques",
  text_2: "text_2",
  text_3:
    "You use less chemical and achieve better results. Canadian Professional Cleaning is committed to stopping the spread of infectious diseases, reducing the environmental footprint and protecting your bottom line!",
  text_4: "",
  quoteText_1:
    "Designed to protect every surface in facilities that demand a healthier environment, by treating entire areas, including sensitive equipment and hidden surfaces without the threat of cross contaminations",
  quoteText_2: "quoteText_2",
  adminImage: "",
  admin: "",
  adminText: "",
  commentsTitle: "commentsTitle",
  commentsPost: "commentsPost",
  postText: "postText",
  iconList: [
    {
      id: 1,
      link: "bg-white facebook",
      iconClass: "fab fa-facebook-f",
    },
    {
      id: 2,
      link: "bg-white twitter",
      iconClass: "fab fa-twitter",
    },
    {
      id: 3,
      link: "bg-white google-plus",
      iconClass: "fab fa-google-plus-g",
    },
  ],
  commentsData: [
    {
      id: 1,
      commentsImage:
        "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/avatar-2.png",
      commentsAuthor: "Junaid Hasan",
      replyText: "Reply",
      commentsText_1: "",
      commentsText_2: "",
    },
    {
      id: 2,
      commentsImage:
        "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/avatar-3.png",
      commentsAuthor: "Jassica William",
      replyText: "Reply",
      commentsText_1: "",
      commentsText_2: "",
    },
  ],
};

class ServiceByoDetails extends Component {
  state = {
    data: CONTENT,
    iconList: CONTENT.iconList,
    commentsData: CONTENT.commentsData,
  };

  render() {
    return (
      <div>
        {/* Single Blog Details */}
        <article className="single-blog-details">
          {/* Blog Thumb */}
          <div className="blog-thumb">
            <Embed
              id="Q_en2ePR5ZM"
              placeholder={this.state.data.image}
              source="youtube"
            />
          </div>
          {/* Blog Content */}
          <div className="blog-content sApp-blog">
            {/* Meta Info */}
            <div className="meta-info d-flex flex-wrap align-items-center py-2">
              {/* <ul>
                                <li className="d-inline-block p-2">By <a className="text-primary" href="/#">{this.state.data.author}</a></li>
                                <li className="d-inline-block p-2"><a href="/#">{this.state.data.date}</a></li>
                                <li className="d-inline-block p-2"><a href="/#">{this.state.data.comments}</a></li>
                            </ul> */}
              {/* Blog Share */}
              <div className="blog-share ml-auto d-none d-sm-block">
                {/* Social Icons */}
                <div className="social-icons d-flex justify-content-center">
                  {this.state.iconList.map((item, idx) => {
                    return (
                      <a key={`bdi_${idx}`} className={item.link} href="https://www.facebook.com/canadianprocleaning/photos">
                        <i className={item.iconClass} />
                        <i className={item.iconClass} />
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* Blog Details */}
            <div className="blog-details">
              <h3 className="blog-title py-3">
                <a href="/#">{this.state.data.title}</a>
              </h3>
             
              <p className="d-none d-sm-block">{this.state.data.text_1}</p>
              <img src="https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/bio-planet-dis.png" alt="" />
              <p className="d-block d-sm-none">{this.state.data.text_2}</p>
              <blockquote className="blockquote px-4 py-3 my-3 ml-4">
                <p className="d-none d-sm-block">
                  {this.state.data.quoteText_1}
                </p>
              
                <p className="d-block d-sm-none">
                  {this.state.data.quoteText_2}
                </p>
              </blockquote>
              <p className="d-none d-sm-block">{this.state.data.text_3}</p>
              <p className="d-block d-sm-none">{this.state.data.text_4}</p>
            </div>
          </div>
        </article>
      </div>
    );
  }
}

export default ServiceByoDetails;
