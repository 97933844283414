import React, { Component } from "react";

const DATA_CONTENT = {
  image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/logo-canadian-cleaning.png",
  text: "Canadian Professional Cleaning is a leading janitorial services in Alberta. Founded more than a decade ago in reaction to the growing awareness to living as eco-friendly as possible.",
  linkText_1: "Useful Links",
  linkText_2: "Product Help",
  linkText_3: "Download",
  iconList: [
    {
      id: 1,
      link: "facebook",
      iconClass: "fab fa-facebook-f",
    },
    {
      id: 2,
      link: "twitter",
      iconClass: "fab fa-twitter",
    },
    {
      id: 3,
      link: "google-plus",
      iconClass: "fab fa-google-plus-g",
    },
    {
      id: 4,
      link: "vine",
      iconClass: "fab fa-vine",
    },
  ],
  footerList_1: [
    {
      id: 1,
      text: "Home",
    },
    {
      id: 2,
      text: "About Us",
    },
    {
      id: 3,
      text: "Services",
    },
    {
      id: 4,
      text: "Blog",
    },
    {
      id: 5,
      text: "Contact",
    },
  ],
  footerList_2: [
    {
      id: 1,
      text: "FAQ",
    },
    {
      id: 2,
      text: "Privacy Policy",
    },
    {
      id: 3,
      text: "Support",
    },
    {
      id: 4,
      text: "Terms & Conditions",
    },
    {
      id: 5,
      text: "Contact",
    },
  ],
  footerList_3: [
    {
      id: 1,
      image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/google-play-black.png",
    },
    {
      id: 2,
      image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/app-store-black.png",
    },
  ],
  footerList_4: [
    {
      id: 1,
      text: "Affiliate program",
    },
    {
      id: 2,
      text: "Terms & Conditions",
    },
    {
      id: 3,
      text: "Privacy Policy",
    },
    {
      id: 4,
      text: "Refund Policy",
    },
  ],
};

class FooterSection extends Component {
  state = {
    data: DATA_CONTENT,
    footerList_4: DATA_CONTENT.footerList_4,
  };

  render() {
    return (
      <footer className="section inner-footer bg-gray ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              {/* Footer Items */}
              <div className="footer-items text-center">
                {/* Logo */}
                <a className="navbar-brand" href="/#">
                  <img className="logo" src={this.state.data.image} alt="" />
                </a>
                <p className="mt-2 mb-3">{this.state.data.text}</p>
                {/* <ul className="d-flex flex-column flex-sm-row justify-content-between">
                  {this.state.footerList_4.map((item, idx) => {
                    return (
                      <li key={`fl_${idx}`} className="py-2">
                        <a href="/#">{item.text}</a>
                      </li>
                    );
                  })}
                </ul> */}
                {/* Copyright Area */}
                <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                {/* Copyright Left */}
                                <div className="copyright-left">© Copyrights 2021 Canadian Professional Cleaning All rights reserved.</div>
                                {/* Copyright Right */}
                                {/* <div className="copyright-right">Made with <i className="fas fa-heart" /> By <a href="/#">Theme Land</a></div> */}
                            </div>
                {/* <div className="copyright-area border-0 pt-3">
                  <p>
                    Made with <i className="fas fa-heart" /> By{" "}
                    <a href="/#">Theme Land</a>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterSection;
