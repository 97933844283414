import React from 'react';
// import VideoPlayer from "react-background-video-player";
import MyRouts from './routers/routes'

function App() {
  return (

    <div className="App">
      {/* <VideoPlayer
        className="video"
        src={
          "" 
        }
        autoPlay={true}
        muted={true}
      /> */}
      <MyRouts />
    </div>
  );
}

export default App;
