import React from 'react';

// const VIDEO_WIDTH = 360;
// const VIDEO_HEIGHT = 640;

const VideoBackground = () => {

    return <div>




    </div>;
}
export default VideoBackground;
