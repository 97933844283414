import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { Message, Menu, Icon, Label } from "semantic-ui-react";
const images = [
  {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing01.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing01.jpg",
  },
  {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing02.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing02.jpg",
  },
  {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing03.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing03.jpg",
  }, {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing04.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing04.jpg",
  }, {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing05.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing05.jpg",
  }, {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing06.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing06.jpg",
  }, {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing07.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing07.jpg",
  }, {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing08.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing08.jpg",
  }, {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing09.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing09.jpg",
  }, {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing10.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing10.jpg",
  }, {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing11.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing11.jpg",
  }, {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing12.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing12.jpg",
  }, {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing13.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing13.jpg",
  }, {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing14.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing14.jpg",
  }, {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing15.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing15.jpg",
  }, {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing16.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing16.jpg",
  }, {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing17.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing17.jpg",
  }, {
    original: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing18.jpg",
    thumbnail: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing18.jpg",
  }
];
const data = [
  {
    image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing01.jpg",
  },
  {
    image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing02.jpg",
  },
  {
    image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing03.jpg",
  },
  {
    image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing04.jpg",
  },
  {
    image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing05.jpg",
  },
  {
    image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing06.jpg",
  },
  {
    image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing07.jpg",
  },
  {
    image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing08.jpg",
  },
  {
    image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing09.jpg",
  },
  {
    image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing10.jpg",
  },
  {
    image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing11.jpg",
  },
  {
    image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing12.jpg",
  },
  {
    image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing13.jpg",
  },
  {
    image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing14.jpg",
  },
  {
    image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing15.jpg",
  },
  {
    image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing15.jpg",
  },
];
const CONTENT = {
  title: "FLOOR WAXING",
  image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing17.jpg",
  author: "Anna Swford",
  date: "05 Feb, 2021",
  comments: "2 Comments",
  text_1: "text_1",
  text_2: "text_2",
  text_3: "text_3",
  text_4: "",
  quoteText_1: "quoteText_1",
  quoteText_2: "quoteText_2",
  adminImage: "",
  admin: "",
  adminText: "",
  commentsTitle: "commentsTitle",
  commentsPost: "commentsPost",
  postText: "postText",
  iconList: [
    {
      id: 1,
      link: "bg-white facebook",
      iconClass: "fab fa-facebook-f",
    },
    {
      id: 2,
      link: "bg-white twitter",
      iconClass: "fab fa-twitter",
    },
    {
      id: 3,
      link: "bg-white google-plus",
      iconClass: "fab fa-google-plus-g",
    },
  ],
  commentsData: [
    {
      id: 1,
      commentsImage: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/avatar-2.png",
      commentsAuthor: "Junaid Hasan",
      replyText: "Reply",
      commentsText_1: "",
      commentsText_2: "",
    },
    {
      id: 2,
      commentsImage: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/avatar-3.png",
      commentsAuthor: "Jassica William",
      replyText: "Reply",
      commentsText_1: "",
      commentsText_2: "",
    },
  ],
};
function ServiceByoDetails() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

      <Modal show={show} onHide={handleClose}   size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header closeButton>
          <Modal.Title> FLOOR WAXING</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageGallery items={images} />
        </Modal.Body>
        <Modal.Footer >
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <article className="single-blog-details">
        {/* Blog Thumb */}
        <div className="blog-thumb">

          
          <a href="# " onClick={handleShow}>
            <img src={CONTENT.image} alt="" width="400" height="400"/>
          </a>
        </div>
        {/* Blog Content */}
        <div className="blog-content sApp-blog">
          {/* Meta Info */}
          <div className="meta-info d-flex flex-wrap align-items-center py-2">
            {/* <ul>
                                <li className="d-inline-block p-2">By <a className="text-primary" href="/#">{data.author}</a></li>
                                <li className="d-inline-block p-2"><a href="/#">{data.date}</a></li>
                                <li className="d-inline-block p-2"><a href="/#">{data.comments}</a></li>
                            </ul> */}
            {/* Blog Share */}
            <div className="blog-share ml-auto d-none d-sm-block">
              {/* Social Icons */}
              <div className="social-icons d-flex justify-content-center">
                {CONTENT.iconList.map((item, idx) => {
                  return (
                    <a key={`bdi_${idx}`} className={item.link} href="https://www.facebook.com/canadianprocleaning/photos">
                      <i className={item.iconClass} />
                      <i className={item.iconClass} />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
          {/* Blog Details */}

          <h3 className="blog-title py-3">
            <a href="/#">{data.title}</a>
          </h3>
        </div>

        <Message info>
          <Message.Header>Floors Stripping</Message.Header>
          <Menu compact>
            <Menu.Item>
              <Icon name="certificate" /> Certified Cleaning Services
              <Label color="blue" floating>
                100%
              </Label>
            </Menu.Item>
          </Menu>
        </Message>

        <Message success>
          <Message.Header>Polishing</Message.Header>

          <Menu compact>
            <Menu.Item>
              <Icon name="certificate" /> Certified Cleaning Services
              <Label color="green" floating>
                100%
              </Label>
            </Menu.Item>
          </Menu>
        </Message>
        <Message negative>
          <Message.Header>Waxing</Message.Header>
          <Menu compact>
            <Menu.Item>
              <Icon name="certificate" /> Certified Cleaning Services
              <Label color="red" floating>
                100%
              </Label>
            </Menu.Item>
          </Menu>
        </Message>

        <div className="row">
          <div className="col-12">
            {/* App Screenshot Slider Area */}
            <div className="app-screenshots">
              {/* Single Screenshot Item */}
              {data.map((item, idx) => {
                return (
                  <div key={`so_${idx}`} className="single-screenshot">
                    <img src={item.image} alt="" width="400" height="400" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </article>
    </>
  );

}

export default ServiceByoDetails;
