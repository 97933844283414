import { gql } from "@apollo/client";

export const getContact = gql`
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      subject
      name
      email
      message
      createdAt
      phoneNumber
      updatedAt
    }
  }
`;
export const listContacts = gql`
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subject
        name
        email
        message
        createdAt
        phoneNumber
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVisitor = gql`
  query GetVisitor($id: ID!) {
    getVisitor(id: $id) {
      id
      name
      page
      sitename
      city
      countryName
      countryCode
      postal
      latitude
      longitude
      ip
      stateName
      createdAt
      updatedAt
    }
  }
`;
export const listVisitors = gql`
  query ListVisitors(
    $filter: ModelVisitorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVisitors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        page
        sitename
        city
        countryName
        countryCode
        postal
        latitude
        longitude
        ip
        stateName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
