import React, { Component } from "react";
// import axios from 'axios';

const dats = {
  preHeading: "Premium",
  preHeadingspan: "Features",
  heading: "Top Rated By Clients",
  headingTwo: "Explore Canadian Professional Cleaning Top Services",
  headingThree:
    "Why Small Business Owner's Loves Canadian Professional Cleaning",
  headingFour: "Why choose Canadian Professional Cleaning?",
  headingText:
    "Canadian Professional Cleaning is a leading janitorial services in Alberta. Founded more than a decade ago in reaction to the growing awareness to living as eco-friendly as possible.",
  headingTexttwo: "",
  featureThumb:
    "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/features_thumb.png",

  featureDataTwo: [
    {
      id: 1,
      iconClass: "flaticon-tap",
      title: "Disinfecting (BYO PLANET)",
      text: "Canadian Professional Cleaning uses sophisticated electrostatic technologies and proven EPA product testing provide the most proactive surface treatments available today.",
      link: "/byo-planet",
    },
    {
      id: 2,
      iconClass: "flaticon-fingerprint",
      title: "JANITORIAL SERVICES",
      text: "For a few years, Canadian Professional Cleaning has been committed to providing you the client with superior Commercial Janitorial Services that are flexible, cost effective and trouble free.",
      link: "/janitorial",
    },
    {
      id: 3,
      iconClass: "flaticon-notification",
      title: "CONSTRUCTION CLEAN UP",
      text: "For a few years, Canadian Professional Cleaning has been providing the finishing touch to your completed facility with superior Construction Cleaning that is flexible, cost effective and trouble free",
      link: "/construction",
    },
    {
      id: 4,
      iconClass: "flaticon-place",
      title: "CAMP CLEANING",
      text: "The health and safety of all staff and guests is a paramount concern of Canadian Professional Cleaning Inc.",
      link: "/camp-cleaning",
    },
    {
      id: 5,
      iconClass: "flaticon-settings",
      title: "MAID SERVICES",
      text: "At Canadian Professional Cleaning we use professionally trained, courteous two person teams to clean your home.",
      link: "/maid-service",
    },
    {
      id: 6,
      iconClass: "flaticon-language",
      title: "FLOOR WAXING",
      text: "Floors Stripping, Polishing, and Waxing  : Certified Cleaning Services 100%  ",
      link: "/floor-waxing",
    },
  ],
};

class FeatureSection extends Component {
  // state = {
  //     data: {},
  //     featureDataTwo: []
  // }
  // componentDidMount(){
  //     axios.get(`${BASE_URL}`)
  //         .then(res => {
  //             this.setState({
  //                 data: res.data,
  //                 featureDataTwo: res.data.featureDataTwo
  //             })
  //             // console.log(dats.data)
  //         })
  //     .catch(err => console.log(err))
  // }
  render() {
    return (
      <section id="features" className="section features-area ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2>{dats.headingTwo}</h2>
                <p className="d-none d-sm-block mt-4">{dats.headingText}</p>
                <p className="d-block d-sm-none mt-4">{dats.headingTexttwo}</p>
              </div>
            </div>
          </div>
          <div className="row">
            {dats.featureDataTwo.map((item, idx) => {
              return (
                <div key={`ft_${idx}`} className="col-12 col-md-6 col-lg-4">
                  {/* Icon Box */}
                  <div className="icon-box text-center p-4">
                    {/* Featured Icon */}{" "}
                    <a href={item.link}>
                      <div className="featured-icon mb-3">
                        <span className={item.iconClass} />
                      </div>
                      {/* Icon Text */}
                      <div className="icon-text">
                        <h3 className="mb-2">{item.title}</h3>
                        <p>{item.text}</p>
                      </div>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default FeatureSection;
