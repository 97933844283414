import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
import { Accordion, Icon, Header, List } from "semantic-ui-react";
const data = [
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/construction-cleaning1.jpeg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/construction-cleaning2.jpeg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/construction-cleaning3.jpeg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/construction-cleaning4.jpeg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/construction-cleaning5.jpeg",
  },
];

const images = [
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/construction-cleaning1.jpeg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/construction-cleaning1.jpeg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/construction-cleaning2.jpeg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/construction-cleaning2.jpeg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/construction-cleaning3.jpeg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/construction-cleaning3.jpeg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/construction-cleaning4.jpeg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/construction-cleaning4.jpeg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/construction-cleaning5.jpeg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/construction-cleaning5.jpeg",
  },
];

const CONTENT = {
  title: "CONSTRUCTION CLEAN UP",
  image:
    "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/construction-cleaning1.jpeg",
  author: "Anna Swford",
  date: "05 Feb, 2021",
  comments: "2 Comments",
  text_1:
    "For a few years, Canadian Professional Cleaning has been providing the finishing touch to your completed facility with superior Construction Cleaning that is flexible, cost effective and trouble free.",
  text_2: "text_2",
  text_3: "text_3",
  text_4: "",
  text_5:
    "Over the years, Canadian Professional Cleaning has taken regular steps to ensure that we are effectively meeting the cleaning needs of our clients. We want you to know that we’re committed to earning your business:",
  text_6:
    "and techniques.We understand the challenges associated with facility cleaning, restoration and maintenance. Armed with a dedicated “client first” philosophy, Canadian Professional Cleaning provides the highest quality cleaning and customer service.",

  quoteText_1:
    "At Canadian Professional Cleaning we offer Comprehensive Construction Cleaning Services that include:",
  quoteText_2: "quoteText_2",
  adminImage: "",
  admin: "",
  adminText: "",
  commentsTitle: "commentsTitle",
  commentsPost: "commentsPost",
  postText: "postText",
  iconList: [
    {
      id: 1,
      link: "bg-white facebook",
      iconClass: "fab fa-facebook-f",
    },
    {
      id: 2,
      link: "bg-white twitter",
      iconClass: "fab fa-twitter",
    },
    {
      id: 3,
      link: "bg-white google-plus",
      iconClass: "fab fa-google-plus-g",
    },
  ],
  commentsData: [
    {
      id: 1,
      commentsImage:
        "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/avatar-2.png",
      commentsAuthor: "Junaid Hasan",
      replyText: "Reply",
      commentsText_1: "",
      commentsText_2: "",
    },
    {
      id: 2,
      commentsImage:
        "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/avatar-3.png",
      commentsAuthor: "Jassica William",
      replyText: "Reply",
      commentsText_1: "",
      commentsText_2: "",
    },
  ],
};

class ServiceByoDetails extends Component {
  state = {
    data: CONTENT,
    iconList: CONTENT.iconList,
    commentsData: CONTENT.commentsData,
  };
  activeIndex = this.state;

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  render() {
    const { activeIndex } = this.state;
    return (
      <div>
        {/* Single Blog Details */}
        <article className="single-blog-details">
          {/* Blog Thumb */}
          <div className="blog-thumb">
            <img src={this.state.data.image} alt="" />
          </div>
          {/* Blog Content */}
          <div className="blog-content sApp-blog">
            {/* Meta Info */}
            <div className="meta-info d-flex flex-wrap align-items-center py-2">
              {/* <ul>
                                <li className="d-inline-block p-2">By <a className="text-primary" href="/#">{this.state.data.author}</a></li>
                                <li className="d-inline-block p-2"><a href="/#">{this.state.data.date}</a></li>
                                <li className="d-inline-block p-2"><a href="/#">{this.state.data.comments}</a></li>
                            </ul> */}
              {/* Blog Share */}
              <div className="blog-share ml-auto d-none d-sm-block">
                {/* Social Icons */}
                <div className="social-icons d-flex justify-content-center">
                  {this.state.iconList.map((item, idx) => {
                    return (
                      <a key={`bdi_${idx}`} className={item.link} href="https://www.facebook.com/canadianprocleaning/photos">
                        <i className={item.iconClass} />
                        <i className={item.iconClass} />
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* Blog Details */}
            <div className="blog-details">
              <h3 className="blog-title py-3">
                <a href="/#">{this.state.data.title}</a>
              </h3>
              <p className="d-none d-sm-block">{this.state.data.text_1}</p>
              <p className="d-block d-sm-none">{this.state.data.text_2}</p>
              <blockquote className="blockquote px-4 py-3 my-3 ml-4">
                <p className="d-none d-sm-block">
                  {this.state.data.quoteText_1}
                </p>
                <p className="d-block d-sm-none">
                  {this.state.data.quoteText_2}
                </p>
              </blockquote>
              {/* <p className="d-none d-sm-block">{this.state.data.text_3}</p> */}
              <p className="d-block d-sm-none">{this.state.data.text_4}</p>
            </div>
          </div>

          <div className="blog-details">
            <Accordion fluid styled>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" color="blue" />
                Residential
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <List bulleted>
                  <List.Item>All Surfaces Washed</List.Item>
                  <List.Item>High Dust Removal</List.Item>
                  <List.Item>Stain Removal, Scrubbing, Dusting</List.Item>
                  <List.Item>
                    Vacuuming of All Surfaces Including Trim Work & Furniture
                  </List.Item>
                  <List.Item>
                    Cleaning the Insides of Desks & Cabinets
                  </List.Item>
                  <List.Item>
                    Full Sanitization of Kitchens & Bathrooms{" "}
                  </List.Item>
                  <List.Item>
                    Scrub Floors & Tiles, Polish Steel, Wipe Walls and More{" "}
                  </List.Item>
                  <List.Item>Floor Cleaning, Waxing & Buffing </List.Item>
                  <List.Item> Cleaning Window Sills & Window Frames </List.Item>
                  <List.Item>
                    Window & Glass Cleaning Including Scraping & Etching removal
                  </List.Item>
                  <List.Item>
                    Bonded, Insured, Uniformed & Supervised Staff{" "}
                  </List.Item>
                  <List.Item>
                    Equipment, Supplies & Transportation Provided
                  </List.Item>
                  <List.Item>
                    Environmentally Friendly Cleaning Products
                  </List.Item>
                </List>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" color="blue" />
                Industrial
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 1}>
                <List bulleted>
                  <List.Item>All Surfaces Washed</List.Item>
                  <List.Item>High Dust Removal</List.Item>
                  <List.Item>Stain Removal, Scrubbing, Dusting </List.Item>
                  <List.Item>
                    Vacuuming of All Surfaces Including Trim Work & Furniture
                  </List.Item>
                  <List.Item>
                    Cleaning the Insides of Desks & Cabinets{" "}
                  </List.Item>
                  <List.Item>
                    Full Sanitization of Kitchens & Bathrooms
                  </List.Item>
                  <List.Item>
                    Scrub Floors & Tiles, Polish Steel, Wipe Walls and More
                  </List.Item>
                  <List.Item>Floor Cleaning, Waxing & Buffing </List.Item>
                  <List.Item>Cleaning Window Sills & Window Frames </List.Item>
                  <List.Item>
                    Window & Glass Cleaning Including Scraping
                  </List.Item>
                  <List.Item>
                    Etching removal ✪ Bonded, Insured, Uniformed & Supervised
                    Staff{" "}
                  </List.Item>
                  <List.Item>
                    Equipment, Supplies & Transportation Provided{" "}
                  </List.Item>
                  <List.Item>
                    {" "}
                    Environmentally Friendly Cleaning Products
                  </List.Item>
                </List>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" color="blue" />
                Others
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 2}>
                <List bulleted>
                  <List.Item>
                    Our cleaning teams are fully equipped with equipment and
                    products to leave your home sparkling clean
                  </List.Item>
                  <List.Item>
                    Our cleaning products are environmentally friendly, which
                    means a healthier environment inside and out
                  </List.Item>
                  <List.Item>
                    Our staff are uniformed, arrive in a company vehicle and are
                    bonded and insured for your peace of mind.
                  </List.Item>
                </List>
              </Accordion.Content>
            </Accordion>
          </div>
          <div className="row">
            <div className="col-12">
              {/* App Screenshot Slider Area */}
              <div className="app-screenshots">
                {/* Single Screenshot Item */}
                {data.map((item, idx) => {
                  return (
                    <div key={`so_${idx}`} className="single-screenshot">
                      <img src={item.image} alt="" />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <Header size="medium">Designed to meet your cleaning needs.</Header>
          <div className="blog-details">
            <p className="d-none d-sm-block">{this.state.data.text_5}</p>
            <ListGroup as="ol" numbered>
              <ListGroup.Item as="li">
                {" "}
                By providing a Custom Cleaning Program specially designed to
                meet your cleaning requirements.
              </ListGroup.Item>
              <ListGroup.Item as="li">
                {" "}
                By providing Dedicated Client Care and Support Team that’s ready
                to take care of your needs.
              </ListGroup.Item>
              <ListGroup.Item as="li">
                {" "}
                By providing you a World Class Cleaning Team and Unsurpassed
                Service.
              </ListGroup.Item>
              <ListGroup.Item as="li">
                {" "}
                By regularly adding to our set of Leading-Edge Services so that
                you always have access to the latest products
              </ListGroup.Item>
            </ListGroup>

            <p className="d-none d-sm-block">{this.state.data.text_6}</p>
          </div>
        </article>
      </div>
    );
  }
}

export default ServiceByoDetails;
