import React from "react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";
import "react-image-gallery/styles/css/image-gallery.css";
import { BrowserRouter } from "react-router-dom";

import Amplify from "aws-amplify";
import amplifyConfig from "./config/amplify";

import "semantic-ui-css/semantic.min.css";
import "react-calendar/dist/Calendar.css";
import "./index.css";
import App from "./App";

import { ApolloClient, ApolloProvider } from "@apollo/client";
import { ApolloLink, createHttpLink } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";

import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

Amplify.configure({
  Auth: {
    identityPoolId: amplifyConfig.cognito.IDENTITY_POOL_ID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: amplifyConfig.cognito.REGION, // REQUIRED - Amazon Cognito Region
    userPoolId: amplifyConfig.cognito.USER_POOL_ID, //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: amplifyConfig.cognito.APP_CLIENT_ID, //OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    AWSS3: {
      bucket: amplifyConfig.s3.BUCKET, //REQUIRED -  Amazon S3 bucket name
      region: amplifyConfig.s3.REGION, //OPTIONAL -  Amazon service region
    },
  },
});

const url = process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT;
const region = process.env.REACT_APP_APPSYNC_GRAPHQL_REGION;
const auth = {
  type: process.env.REACT_APP_APPSYNC_GRAPHQL_TYPE_AUTH,
  apiKey: process.env.REACT_APP_APPSYNC_GRAPHQL_API_KEY,
};

const httpLink = createHttpLink({ uri: url });

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink(url, httpLink),
]);

const clientApollo = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  headers: {
    authorization: localStorage.getItem("token") || "",
    "client-name": "Space Explorer [web]",
    "client-version": "1.0.0",
  },
});

const rootEl = document.getElementById("root");

function render() {
  ReactDOM.render(
    <BrowserRouter>
      <ApolloProvider client={clientApollo}>
        <App />
      </ApolloProvider>
    </BrowserRouter>,
    rootEl
  );
}

if (module.hot) {
  module.hot.accept("./App", function () {
    setTimeout(render);
  });
}

render();
