import React, { Component } from 'react';

const initData = {
    iconClass: "far fa-lightbulb text-primary mr-1",
    preHeading: "Canadian Professional ",
    preHeadingspan: "Cleaning",
    heading: "Quality Work Through Customer Dedication Satisfaction Experience",
    headingText: "",
    headingTexttwo: ""
}

const data = [
    {
        image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing10.jpg",
    },
    {
        image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing08.jpg"
    },
    {
        image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/waxing09.jpg"
    },
    {
        image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/janitorial01.jpeg"
    },
    {
        image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/janitorial02.jpg"
    }
]

class ScreenshotSection extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="screenshots" className="section screenshots-area ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-7">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        <span className="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                            <i className={this.state.initData.iconClass} />
                            <span className="text-primary">{this.state.initData.preHeading}</span> {this.state.initData.preHeadingspan}
                        </span>
                        <h2 className="text-capitalize">{this.state.initData.heading}</h2>
                        <p className="d-none d-sm-block mt-4">{this.state.initData.headingText}</p>
                        <p className="d-block d-sm-none mt-4">{this.state.initData.headingTexttwo}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-12">
                        {/* App Screenshot Slider Area */}
                        <div className="app-screenshots">
                            {/* Single Screenshot Item */}
                            {data.map((item, idx) => {
                                return(
                                    <div key={`so_${idx}`} className="single-screenshot">
                                        <img src={item.image} alt="" />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ScreenshotSection;