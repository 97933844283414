import React, { Component } from "react";
// import axios from "axios";

// const BASE_URL =
//   "https://my-json-server.typicode.com/themeland/sapp/themeOneFeatureSection";

const dats = {
  preHeading: "Canadian Professional",
  preHeadingspan: "Cleaning",
  heading: "Your preferred cleaning service company Alberta",
  headingTwo: "Explore Premium Features",
  headingThree: "Why Small Business Owner's Loves Canadian Professional Cleaning",
  headingFour: "Top Rated By Clients",
  headingText:
    "Wide Proudly serving multiple locations in Alberta.",
  headingTexttwo:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
  featureThumb: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/features_thumb.png",
  featureData: [
    {
      id: 1,
      image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/featured_image_1.png",
      title: "Disinfecting (BYO PLANET)",
      text: "Canadian Professional Cleaning uses sophisticated electrostatic technologies and proven EPA product testing provide the most proactive surface treatments available today.",
    },
    {
      id: 2,
      image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/featured_image_2.png",
      title: "JANITORIAL SERVICES",
      text: "For a few years, Canadian Professional Cleaning has been committed to providing you the client with superior Commercial Janitorial Services that are flexible.",
    },
    {
      id: 3,
      image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/featured_image_3.png",
      title: "CAMP CLEANING",
      text: "The health and safety of all staff and guests is a paramount concern of Canadian Professional Cleaning Inc.",
    },
    {
      id: 4,
      image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/featured_image_3.png",
      title: "MAID SERVICES",
      text: "At Canadian Professional Cleaning we use professionally trained, courteous two person teams to clean your home.",
    },
    {
      id: 5,
      image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/featured_image_3.png",
      title: "FLOOR WAXING",
      text: "Floors Stripping, Polishing, and Waxing  : Certified Cleaning Services 100%  ",
    },
    {
      id: 6,
      image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/featured_image_3.png",
      title: "CONSTRUCTION CLEAN UP",
      text: "For a few years, Canadian Professional Cleaning has been providing the finishing touch to your completed facility with superior Construction Cleaning.",
    },
  ],
  featureData_1: [
    {
      id: 1,
      image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/featured_image_4.png",
      title: "Easy to Customize",
      text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.",
    },
    {
      id: 2,
      image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/featured_image_5.png",
      title: "Responsive Design",
      text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.",
    },
    {
      id: 3,
      image: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/featured_image_6.png",
      title: "Help Documentation",
      text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.",
    },
  ],
  featureDataTwo: [
    {
      id: 1,
      iconClass: "flaticon-tap",
      title: "Fully functional",
      text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.",
    },
    {
      id: 2,
      iconClass: "flaticon-fingerprint",
      title: "Fingerprint Data",
      text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.",
    },
    {
      id: 3,
      iconClass: "flaticon-notification",
      title: "Tap Notification",
      text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.",
    },
    {
      id: 4,
      iconClass: "flaticon-place",
      title: "Location Tracking",
      text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.",
    },
    {
      id: 5,
      iconClass: "flaticon-settings",
      title: "Powerful Settings",
      text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.",
    },
    {
      id: 6,
      iconClass: "flaticon-language",
      title: "Multiple Language",
      text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.",
    },
  ],
};

class FeatureSection extends Component {
//   state = {
//     data: {},
//     featureData: [],
//   };
//   componentDidMount() {
//     axios
//       .get(`${BASE_URL}`)
//       .then((res) => {
//         this.setState({
//           data: res.data,
//           featureData: res.data.featureData,
//         });
//         // console.log(dats)
//       })
//       .catch((err) => console.log(err));
//   }

  render() {
    return (
      <section
        id="features"
        className="section features-area style-two overflow-hidden ptb_100"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-7">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <span className="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                  <i className="far fa-lightbulb text-primary mr-1" />
                  <span className="text-primary">
                    {dats.preHeading}
                  </span>{" "}
                  {dats.preHeadingspan}
                </span>
                <h2>{dats.heading}</h2>
                <p className="d-none d-sm-block mt-4">
                  {dats.headingText}
                </p>
                <p className="d-block d-sm-none mt-4">
                  {dats.headingTexttwo}
                </p>
              </div>
            </div>
          </div>
 
        </div>
      </section>
    );
  }
}

export default FeatureSection;
