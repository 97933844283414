import React, { useState } from "react";
import { Button, Modal, ListGroup } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import { Header } from "semantic-ui-react";

const images = [
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/janitorial01.jpeg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/janitorial01.jpeg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/janitorial02.jpg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/janitorial02.jpg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/janitorial03.jpg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/janitorial03.jpg",
  },
  {
    original:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/janitorial04.jpeg",
    thumbnail:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/janitorial04.jpeg",
  },
];

const CONTENT = {
  title: "JANITORIAL SERVICES",
  image:
    "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/janitorial01.jpeg",
  author: "Anna Swford",
  date: "05 Feb, 2021",
  comments: "2 Comments",
  text_1:
    "For a few years, Canadian Professional Cleaning has been committed to providing you the client with superior Commercial Janitorial Services that are flexible, cost effective and trouble free.",
  text_2: "text_2",
  text_3:
    "At Canadian Professional Cleaning we offer Janitorial Services that include:",
  text_4:
    "Our concept of contract office cleaning is a simple one. Our employees are trained to meet your specific needs and janitorial prerequisites. We custom design detailed cleaning tasks to be done at precise intervals, based on your requirements. We do not believe in shortcuts. We “say what we do and do what we say",
  quoteText_1:
    "For a few years, Canadian Professional Cleaning has been committed to providing you the client with superior Commercial Janitorial Services that are flexible, cost effective and trouble free.",
  quoteText_2: "quoteText_2",
  adminImage: "",
  admin: "",
  text_5:
    "Over the years, Canadian Professional Cleaning has taken regular steps to ensure that we are effectively meeting the cleaning needs of our clients. We want you to know that we’re committed to earning your business:",
  text_6:
    "and techniques.We understand the challenges associated with facility cleaning, restoration and maintenance. Armed with a dedicated “client first” philosophy, Canadian Professional Cleaning provides the highest quality cleaning and customer service.",
  commentsPost: "commentsPost",
  postText: "postText",
  iconList: [
    {
      id: 1,
      link: "bg-white facebook",
      iconClass: "fab fa-facebook-f",
    },
    {
      id: 2,
      link: "bg-white twitter",
      iconClass: "fab fa-twitter",
    },
    {
      id: 3,
      link: "bg-white google-plus",
      iconClass: "fab fa-google-plus-g",
    },
  ],
  commentsData: [
    {
      id: 1,
      commentsImage:
        "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/avatar-2.png",
      commentsAuthor: "Junaid Hasan",
      replyText: "Reply",
      commentsText_1: "",
      commentsText_2: "",
    },
    {
      id: 2,
      commentsImage:
        "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/avatar-3.png",
      commentsAuthor: "Jassica William",
      replyText: "Reply",
      commentsText_1: "",
      commentsText_2: "",
    },
  ],
};

const data = [
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/janitorial01.jpeg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/janitorial02.jpg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/janitorial03.jpg",
  },
  {
    image:
      "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/company/janitorial04.jpeg",
  },
];

function ServiceByoDetails() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // state = {
  //   data: CONTENT,
  //   iconList: CONTENT.iconList,
  //   commentsData: CONTENT.commentsData,
  // };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> Janitorial services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageGallery items={images} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Single Blog Details */}
      <article className="single-blog-details">
        {/* Blog Thumb */}
        <div className="blog-thumb">
          <a href="# " onClick={handleShow}>
            <img src={CONTENT.image} alt="" />
          </a>
        </div>
        {/* Blog Content */}
        <div className="blog-content sApp-blog">
          {/* Meta Info */}
          <div className="meta-info d-flex flex-wrap align-items-center py-2">
            {/* <ul>
                                <li className="d-inline-block p-2">By <a className="text-primary" href="/#">{data.author}</a></li>
                                <li className="d-inline-block p-2"><a href="/#">{data.date}</a></li>
                                <li className="d-inline-block p-2"><a href="/#">{data.comments}</a></li>
                            </ul> */}
            {/* Blog Share */}
            <div className="blog-share ml-auto d-none d-sm-block">
              {/* Social Icons */}
              <div className="social-icons d-flex justify-content-center">
                {CONTENT.iconList.map((item, idx) => {
                  return (
                    <a key={`bdi_${idx}`} className={item.link} href="https://www.facebook.com/canadianprocleaning/photos">
                      <i className={item.iconClass} />
                      <i className={item.iconClass} />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>

          {/* Blog Details */}
          <div className="blog-details">
            <h3 className="blog-title py-3">
              <a href="/#">{CONTENT.title}</a>
            </h3>
            <p className="d-none d-sm-block">{CONTENT.text_1}</p>
            <p className="d-block d-sm-none">{CONTENT.text_2}</p>
            <blockquote className="blockquote px-4 py-3 my-3 ml-4">
              <p className="d-none d-sm-block">{CONTENT.quoteText_1}</p>
              <p className="d-block d-sm-none">{CONTENT.quoteText_2}</p>
            </blockquote>
            <p className="d-none d-sm-block">{CONTENT.text_3}</p>
            <p className="d-block d-sm-none">{CONTENT.text_4}</p>
          </div>
        </div>

        {/* Blog Comments */}
        <div>
          <ListGroup as="ol" numbered>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              Day & Night Cleaning
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              Custom Cleaning Programs
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              Custom Cleaning Programs
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              {" "}
              Dust Removal, Vacuuming & Trash Removal
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              Restroom & Kitchen Sanitation
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              {" "}
              Window & Glass Cleaning
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              Metal & Brightwork Polishing
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              Dust Mopping & Wet Mopping
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              Floor Stripping, Waxing, Buffing
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              Free On-Site Estimates
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              On-Site Management & Porter Services
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              {" "}
              Over 100 Bonded, Insured, Uniformed & Supervised Staff
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              {" "}
              Environmentally Friendly Cleaning Products
            </ListGroup.Item>
          </ListGroup>

          <p className="d-none d-sm-block">{data.text_4}</p>
        </div>
        <Header size="medium">Designed to meet your cleaning needs.</Header>
        <div className="blog-details">
          <p className="d-none d-sm-block">{data.text_5}</p>

          <ListGroup as="ol" numbered>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              {" "}
              By providing a Custom Cleaning Program specially designed to meet
              your cleaning requirements.
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              {" "}
              By providing Dedicated Client Care and Support Team that’s ready
              to take care of your needs.
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              {" "}
              By providing you a World Class Cleaning Team and Unsurpassed
              Service.
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              {" "}
              By regularly adding to our set of Leading-Edge Services so that
              you always have access to the latest products
            </ListGroup.Item>
          </ListGroup>

          <p className="d-none d-sm-block">{data.text_6}</p>
        </div>

        <div className="row">
          <div className="col-12">
            {/* App Screenshot Slider Area */}
            <div className="app-screenshots">
              {/* Single Screenshot Item */}
              {data.map((item, idx) => {
                return (
                  <div key={`so_${idx}`} className="single-screenshot">
                    <img src={item.image} alt="" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

export default ServiceByoDetails;
