import React, { Component } from "react";


const dataPricing = {
  iconClass: "far fa-lightbulb text-primary mr-1",
  preHeading: "Flexible",
  preHeadingspan: "Pricing",
  heading: "Canadian Professional Cleaning Pricing ",
  headingText: "",
  headingTexttwo: "",
  text: "Not sure what to choose?",
  textLink: "Contact Us",
  pricingData: [
    {
      id: 1,
      planImage: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/price_basic.png",
      planTitle: "Basic",
      priceSub: "$",
      planPrice: "3000",
      planBtn: "Sign Up",
      li_1: "Disinfecting (BYO PLANET)",
      li_2: "JANITORIAL SERVICES",
      li_3: "FLOOR WAXING",
      li_4: "MAID SERVICE",
    },
    {
      id: 2,
      planImage: "https://s3.ca-central-1.amazonaws.com/canadianprofessionalcleaning.com/public/img/price_premium.png",
      planTitle: "Pro",
      priceSub: "$",
      planPrice: "5000",
      planBtn: "Sign Up",
      li_1: "Disinfecting (BYO PLANET)",
      li_2: "JANITORIAL SERVICES",
      li_3: "FLOOR WAXING",
      li_4: "MAID SERVICE",
    },
  ],
};
class PricingSection extends Component {
  render() {
    return (
      <section
        id="pricing"
        className="section price-plan-area bg-gray overflow-hidden ptb_100"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-7">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2>{dataPricing.heading}</h2>
                <p className="d-none d-sm-block mt-4">
                  {dataPricing.headingText}
                </p>
                <p className="d-block d-sm-none mt-4">
                  {dataPricing.headingTexttwo}
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-lg-8">
              <div className="row price-plan-wrapper">
                {dataPricing.pricingData.map((item, idx) => {
                  return (
                    <div key={`p_${idx}`} className="col-12 col-md-6 mt-4">
                      {/* Single Price Plan */}
                      <div className="single-price-plan text-center p-5">
                        {/* Plan Thumb */}
                        <div className="plan-thumb">
                          <img
                            className="avatar-lg"
                            src={item.planImage}
                            alt=""
                          />
                        </div>
                        {/* Plan Title */}
                        <div className="plan-title my-2 my-sm-3">
                          <h3 className="text-uppercase">{item.planTitle}</h3>
                        </div>
                        {/* Plan Price */}
                        <div className="plan-price pb-2 pb-sm-3">
                          <h1 className="color-primary">
                            <small className="fw-7">{item.priceSub}</small>
                            {item.planPrice}
                          </h1>
                        </div>
                        {/* Plan Description */}
                        <div className="plan-description">
                          <ul className="plan-features">
                            <li className="border-top py-3">{item.li_1}</li>
                            <li className="border-top py-3">{item.li_2}</li>
                            <li className="border-top py-3">{item.li_3}</li>
                            <li className="border-top border-bottom py-3">
                              {item.li_4}
                            </li>
                          </ul>
                        </div>
                        {/* Plan Button */}
                        <div className="plan-button">
                          <a href="/#" className="btn mt-4">
                            {item.planBtn}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row justify-content-center pt-5">
            <p className="text-body pt-4 fw-5">
              {dataPricing.text} <a href="/#">{dataPricing.textLink}</a>
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default PricingSection;
